import React from 'react'
import { graphql } from 'gatsby'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Formik, Field } from 'formik'
import MaterialIcon from '@material/react-material-icon'
import Button from '@material/react-button'
import TextField, { HelperText, Input } from '@material/react-text-field'
import Yup from 'yup'
import classNames from 'classnames'
import PageLayout from '../../components/layout'
import PageSummary from '../../components/PageSummary'
import isEmpty from 'lodash/isEmpty'
import bckBackendUrl from '../../images/images/bck-backend.jpg'
import { SoloCell } from '../../components/Grid'
import injectNestedIntlProvider from '../../components/NestedIntlProvider'

const URL = process.env.CONTACT_URL

export const TextFieldComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  textFieldProps,
  textarea,
  leadingIcon,
  label,
  ...props
}) => {
  const isValid = !touched[field.name] || !errors[field.name]
  return (
    <div>
      <TextField
        className={classNames({ 'mdc-text-field--invalid': !isValid })}
        label={label}
        leadingIcon={leadingIcon}
        helperText={
          isValid ? (
            <HelperText persistent />
          ) : (
            <HelperText validation persistent>
              {errors[field.name]}
            </HelperText>
          )
        }
        textarea={textarea}
        {...textFieldProps}
      >
        <Input id={field.name} {...field} {...props} />
      </TextField>
    </div>
  )
}

export const InputComponent = props => <TextFieldComponent {...props} />

export const TextareaComponent = props => (
  <TextFieldComponent textarea rows="5" {...props} />
)

export const FormRow = ({ children }) => (
  <div className="form__row">{children}</div>
)

const ContactForm = injectIntl(props => {
  return (
    <Formik
      validationSchema={createValidationSchema(props.intl)}
      initialValues={{
        language: props.intl.locale,
      }}
      onSubmit={handleSubmit}
      render={({ errors, dirty, isSubmitting, handleSubmit, status }) => {
        let errorPanel
        if (status && status.ok) {
          return (
            <div className="form__successful">
              <FormattedMessage id="pages.contact.response.success" />
            </div>
          )
        }
        if (status && !status.ok) {
          errorPanel = (
            <div className="form__error">
              <FormattedMessage id="pages.contact.response.error" />
            </div>
          )
        }
        return (
          <form onSubmit={handleSubmit}>
            {errorPanel}
            <FormRow>
              <Field
                name="name"
                label={<FormattedMessage id="pages.contact.label.name" />}
                leadingIcon={<MaterialIcon icon="person" />}
                component={InputComponent}
              />
            </FormRow>
            <FormRow>
              <Field
                name="email"
                label={<FormattedMessage id="pages.contact.label.email" />}
                leadingIcon={<MaterialIcon icon="email" />}
                component={InputComponent}
              />
            </FormRow>
            <FormRow>
              <Field
                name="message"
                label={<FormattedMessage id="pages.contact.label.message" />}
                component={TextareaComponent}
              />
            </FormRow>

            <Button
              className="mdc-button--raised"
              type="submit"
              disabled={isSubmitting || !dirty || !isEmpty(errors)}
            >
              {isSubmitting ? (
                <FormattedMessage id="pages.contact.submitting" />
              ) : (
                <FormattedMessage id="pages.contact.submit" />
              )}
            </Button>
          </form>
        )
      }}
    />
  )
})

const requiredMsg = (intl, field) =>
  intl.formatMessage(
    {
      id: 'forms.validation.required',
    },
    {
      field: intl.formatMessage({ id: `pages.contact.label.${field}` }),
    }
  )

const createValidationSchema = intl =>
  Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'forms.validation.email_invalid' }))
      .required(requiredMsg(intl, 'email')),
    name: Yup.string().required(requiredMsg(intl, 'name')),
    message: Yup.string().required(requiredMsg(intl, 'message')),
  })

const handleSubmit = (values, { setSubmitting, setStatus, resetForm }) => {
  fetch(URL, {
    method: 'POST',
    body: JSON.stringify(values),
  })
    .then(response => {
      if (response.ok) {
        resetForm()
        setStatus({ ok: true })
        return response.blob()
      }
      setStatus({ ok: false })
      throw new Error('Network response was not ok.')
    })
    .then(() => {})
    .catch(error => {
      console.log(error)
      return
    })
    .then(() => {
      setSubmitting(false)
    })
}

const Contact = ({ data }) => (
  <FormattedMessage id="pages.contact.title">
    {title => (
      <PageLayout
        title={title}
        pageSummary={
          <PageSummary
            title={<FormattedMessage id="pages.contact.title" />}
            lead={<FormattedMessage id="pages.contact.lead" />}
            imageFluid={data.summaryImage && data.summaryImage.childImageSharp.fluid}
          />
        }
        pageClassName="page--contact"
      >
        <SoloCell cellClassName="mdc-layout-grid__cell--span-9-desktop">
          <ContactForm />
        </SoloCell>
      </PageLayout>
    )}
  </FormattedMessage>
)

export default injectNestedIntlProvider(Contact)

export const query = graphql`
  query($language: String!) {
#    summaryImage: file(relativePath: { eq: "bck-contact.jpg" }) {
#      ...SummaryImage
#    }
    messages: allKeyValue(
      filter: {
        file: { relativeDirectory: { eq: "pages" }, name: { eq: $language } }
        key: {
          regex: "/^pages\\.contact/"
        }
      }
    ) {
      edges {
        node {
          key
          value
        }
      }
    }
  }
`
